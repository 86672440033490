<template>
  <div class="main">
    <KTCodePreview v-bind:title="title">
      <!-- button create new -->
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-0">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder btn-sm"
              @click="upsert"
            >
              <i
                style="font-size: 1rem"
                class="flaticon2-add-1"
              ></i>Tạo mới
            </button>
          </div>
        </div>
      </template>
      <!-- reason configuration table -->
      <template v-slot:preview>
        <!-- Header session -->
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row class="mb-5">
              <b-col class="pl-0">
                <b-input
                  v-model="searchName"
                  placeholder="Nhập keywork"
                  size="sm"
                ></b-input>
              </b-col>
              <b-col></b-col>
              <b-col></b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col
                md="1"
                style="padding-left: 0"
              >
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter()"
                >Lọc</b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <!-- End of Header session -->
        <b-row>
          <b-col cols="12">
            <b-table
              :items="items"
              :fields="fields"
              bordered
            >
              <template v-slot:cell(actions)="row">
                <b-dropdown
                  size="sm"
                  id="dropdown-left"
                  no-caret
                  right
                >
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem; padding-right: 0px"
                      class="flaticon2-settings"
                    ></i>
                  </template>
                  <b-dropdown-item @click="editItem(row.item)">
                    <span style="color: #3f4254; font-size: 12px"></span>
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-pen icon-color"
                    ></i>
                    &nbsp; Chỉnh sửa
                  </b-dropdown-item>
                  <b-dropdown-item @click="showDeleteAlert(row.item.id)">
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="font-size: 1rem; color: #d33"
                        class="flaticon2-rubbish-bin-delete-button"
                      ></i>
                      &nbsp; Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <div class="paging">
          <div class="total">
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng số:
              {{ totalItems }}
            </p>
          </div>
          <div class="paging-nav">
            <b-pagination-nav
              class="custom-pagination"
              :link-gen="linkGen"
              v-show="totalPages >= 2"
              :number-of-pages="totalPages"
              use-router
              @change="fetchConfigs"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template> </b-pagination-nav>
          </div>
        </div>
        <b-col></b-col>
        <b-col></b-col>

        <!-- Cấu hình chức vụ - số giờ công -->
        <div class="add-model">
          <b-modal
            ref="my-modal"
            hide-footer
            title="Thông tin cấu hình"
          >
            <b-row>
              <b-col>
                <b-form-group>
                  <label>Số km từ</label>
                  <b-input
                    v-model="config.distanceFrom"
                    placeholder="Nhập số km từ"
                    size="sm"
                  ></b-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <label>Số km đến</label>
                  <b-input
                    v-model="config.distanceTo"
                    placeholder="Nhập số km đến"
                    size="sm"
                  ></b-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group>
                  <label>Số tiền</label>
                  <BInputNumber
                    size="sm"
                    v-model="config.payRate"
                    placeholder="Nhập số tiền"
                    class="text-right"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              class="mt-2"
              variant="btn btn-primary"
              block
              @click="onSaveModal"
            >Lưu</b-button>

            <b-button
              class="mt-3"
              variant="btn btn-outline-secondary"
              block
              @click="hideModal"
            >Đóng</b-button>
          </b-modal>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<style lang="scss" scoped>
.main ::v-deep {
  .paging {
    display: flex;
    justify-content: space-between;
  }
  .form-group label {
    font-weight: 500;
  }
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import {
  makeToastSuccess,
  makeToastFaile,
  removeAccents,
  convertPrice,
} from '@/utils/common';
import BInputNumber from '@/view/base/bootstrap/BInputNumber.vue';

export default {
  components: {
    KTCodePreview,
    BInputNumber,
  },
  data() {
    return {
      title: 'Danh sách cấu hình chi phí vận chuyển',
      totalPages: 1,
      totalItems: 0,
      page: 1,
      currentPage: 1,
      items: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'distanceFrom',
          label: 'Số km từ',
          tdClass: 'text-right',
        },
        {
          key: 'distanceTo',
          label: 'Số km đến',
          tdClass: 'text-right',
        },
        {
          key: 'payRate',
          label: 'Số tiền',
          tdClass: 'text-right',
          formatter: (val) => convertPrice(val),
        },
        {
          key: 'updatedByName',
          label: 'Cập nhật bởi',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
      limit: 10,
      onSaving: false,
      searchName: '',
      config: {
        id: null,
        distanceFrom: 0,
        distanceTo: 0,
        payRate: 0,
      },
    };
  },
  created() {
    this.fetchConfigs();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Cấu hình chi phí vận chuyển',
        route: 'Danh sách cấu hình chi phí vận chuyển',
      },
    ]);
  },
  methods: {
    convertPrice,
    upsertConfig() {
      if (this.onSaving) return;
      this.onSaving = true;
      ApiService.post('/v2/distance-fare-configs', this.config)
        .then(({ data }) => {
          this.onSaving = false;
          makeToastSuccess(data.message);
          this.fetchConfigs();
        })
        .catch((err) => {
          this.onSaving = false;
          if (!err.response) {
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    onSaveConfig() {
      this.upsertConfig();
      this.hideConfigModal();
    },
    onFilter() {
      this.fetchConfigs();
    },
    removeAccents,
    upsert() {
      this.config = {
        id: null,
        distanceFrom: 0,
        distanceTo: 0,
        payRate: 0,
      };
      this.showModal();
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    onSaveModal() {
      this.upsertConfig();
      this.hideModal();
    },
    fetchConfigs: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      const paramQuery = {
        params: {
          page: this.page,
          pageSize: 10,
          searchText: this.searchName,
        },
      };
      ApiService.query('/v2/distance-fare-configs', paramQuery).then(
        (response) => {
          const { dataset, totalPage, totalRow } = response.data.data;
          this.items = [];
          this.items = [...dataset];
          this.totalPages = totalPage || 1;
          this.totalItems = totalRow;
        },
      );
    },
    deleteItem: async function (item) {
      ApiService.delete(`/v2/distance-fare-configs/${item}`)
        .then(({ data }) => {
          makeToastSuccess(data.message);
          this.fetchConfigs();
        })
        .catch((err) => {
          if (!err.response) {
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa cấu hình!',
        text: 'Bạn có chắc muốn xóa cấu hình này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      })
        .then((result) => {
          if (result.value) {
            this.deleteItem(item);
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          this.makeToastFaile(message);
        });
    },
    editItem: function (item) {
      this.config.id = item.id;
      this.config.distanceFrom = item.distanceFrom;
      this.config.distanceTo = item.distanceTo;
      this.config.payRate = item.payRate;
      this.showModal();
    },
  },
};
</script>